import { ensureErrorMessageIsString } from '$lib/commonUtils';
import * as Sentry from "@sentry/sveltekit";

Sentry.init({
	environment: import.meta.env.MODE,
	dsn: "https://f1bbd2c2131a4c4da643888bd894bb84@o4504980660224000.ingest.us.sentry.io/4504982046965760",
	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 1.0,
	// Optional: Initialize Session Replay:
	integrations: [Sentry.replayIntegration()],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0
});

Sentry.setTag('svelteKit', 'browser');

/** @type {import('@sveltejs/kit').HandleClientError} */
export const handleError: import('@sveltejs/kit').HandleClientError = ({ error, event }) => {
	Sentry.captureException(error, { contexts: { sveltekit: { event } } });

	return {
		message: ensureErrorMessageIsString(error)
	};
};
